@import "assets/lumen/variables";
@import "bootstrap";
@import "assets/lumen/bootswatch";
@import "bootstrap-icons";

#layoutOuter {
  background: $primary;
  background: -webkit-linear-gradient(top, $primary, $white);
  background: -moz-linear-gradient(top, $primary, $white);
  background: linear-gradient(to bottom, $primary, $white);
}

// #layoutInner {
//   box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 8px 0 8px -4px rgba(0, 0, 0, 0.8), -8px 0 8px -4px rgba(0, 0, 0, 0.8);
// }

@include media-breakpoint-up(sm) {
  // #layoutInner {
  //   background-color: $white;
  // }
  .navbar {
    border-radius: 8px;
  }
}



.box-shadow-sides {
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 8px 0 8px -4px rgba(0, 0, 0, 0.8), -8px 0 8px -4px rgba(0, 0, 0, 0.8);
}

